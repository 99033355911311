
// @ts-nocheck


export const localeCodes =  [
  "en",
  "ru",
  "kk",
  "az",
  "uz",
  "es",
  "hi",
  "ph",
  "id"
]

export const localeLoaders = {
  "en": [{ key: "../locales/en-EN.ts", load: () => import("../locales/en-EN.ts?hash=566bf387&locale=en" /* webpackChunkName: "locale__app_locales_en_EN_ts" */), cache: false }],
  "ru": [{ key: "../locales/ru-RU.ts", load: () => import("../locales/ru-RU.ts?hash=dc5459f8&locale=ru" /* webpackChunkName: "locale__app_locales_ru_RU_ts" */), cache: false }],
  "kk": [{ key: "../locales/kk-KK.json", load: () => import("../locales/kk-KK.json" /* webpackChunkName: "locale__app_locales_kk_KK_json" */), cache: true }],
  "az": [{ key: "../locales/az-AZ.json", load: () => import("../locales/az-AZ.json" /* webpackChunkName: "locale__app_locales_az_AZ_json" */), cache: true }],
  "uz": [{ key: "../locales/uz-UZ.json", load: () => import("../locales/uz-UZ.json" /* webpackChunkName: "locale__app_locales_uz_UZ_json" */), cache: true }],
  "es": [{ key: "../locales/es-ES.ts", load: () => import("../locales/es-ES.ts?hash=b9aa33a8&locale=es" /* webpackChunkName: "locale__app_locales_es_ES_ts" */), cache: false }],
  "hi": [{ key: "../locales/hi-HI.json", load: () => import("../locales/hi-HI.json" /* webpackChunkName: "locale__app_locales_hi_HI_json" */), cache: true }],
  "ph": [{ key: "../locales/ph-PH.ts", load: () => import("../locales/ph-PH.ts?hash=034553f0&locale=ph" /* webpackChunkName: "locale__app_locales_ph_PH_ts" */), cache: false }],
  "id": [{ key: "../locales/id-ID.ts", load: () => import("../locales/id-ID.ts?hash=52189ade&locale=id" /* webpackChunkName: "locale__app_locales_id_ID_ts" */), cache: false }]
}

export const vueI18nConfigs = [
  () => import("../config/i18n/config.ts?hash=6f1aaafa&config=1" /* webpackChunkName: "~_config_i18n_config_ts_6f1aaafa" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "~/config/i18n/config.ts",
  "locales": [
    {
      "code": "en",
      "files": [
        "locales/en-EN.ts"
      ]
    },
    {
      "code": "ru",
      "files": [
        "locales/ru-RU.ts"
      ]
    },
    {
      "code": "kk",
      "files": [
        "locales/kk-KK.json"
      ]
    },
    {
      "code": "az",
      "files": [
        "locales/az-AZ.json"
      ]
    },
    {
      "code": "uz",
      "files": [
        "locales/uz-UZ.json"
      ]
    },
    {
      "code": "es",
      "files": [
        "locales/es-ES.ts"
      ]
    },
    {
      "code": "hi",
      "files": [
        "locales/hi-HI.json"
      ]
    },
    {
      "code": "ph",
      "files": [
        "locales/ph-PH.ts"
      ]
    },
    {
      "code": "id",
      "files": [
        "locales/id-ID.ts"
      ]
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": true,
  "langDir": "locales/",
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "en",
    "files": [
      {
        "path": "locales/en-EN.ts"
      }
    ]
  },
  {
    "code": "ru",
    "files": [
      {
        "path": "locales/ru-RU.ts"
      }
    ]
  },
  {
    "code": "kk",
    "files": [
      {
        "path": "locales/kk-KK.json"
      }
    ]
  },
  {
    "code": "az",
    "files": [
      {
        "path": "locales/az-AZ.json"
      }
    ]
  },
  {
    "code": "uz",
    "files": [
      {
        "path": "locales/uz-UZ.json"
      }
    ]
  },
  {
    "code": "es",
    "files": [
      {
        "path": "locales/es-ES.ts"
      }
    ]
  },
  {
    "code": "hi",
    "files": [
      {
        "path": "locales/hi-HI.json"
      }
    ]
  },
  {
    "code": "ph",
    "files": [
      {
        "path": "locales/ph-PH.ts"
      }
    ]
  },
  {
    "code": "id",
    "files": [
      {
        "path": "locales/id-ID.ts"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
