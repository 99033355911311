import BkHttp from '~/repository/modules/bk/BkHttp';
import type {
  IGetCaseByNameArgs,
  IGetUserArgs,
  IOpenCaseArgs,
  ICreateAuthUrlArg,
  ISendItemArgs,
  ISellItemArgs,
  IReplaceItemArgs,
} from '~/repository/modules/bk/bk.types';

export default class BkService {
  private readonly bkHttp: BkHttp;
  constructor() {
    this.bkHttp = new BkHttp();
  }

  // cases
  public getAll<T>(bkPrefix: string) {
    return this.bkHttp.getAll<T>(bkPrefix);
  }

  public getCaseByName<T>({ caseName, createAuthUrl }: IGetCaseByNameArgs) {
    return this.bkHttp.getCaseByName<T>({ caseName, createAuthUrl });
  }

  public getCaseByNameForDemo<T>(bkPrefix: string, caseName: string, language: string) {
    return this.bkHttp.getCaseByNameForDemo<T>(bkPrefix, caseName, language);
  }

  public openCase<T>(args: IOpenCaseArgs) {
    return this.bkHttp.openCase<T>(args);
  }

  public openCaseDemo<T>(bkPrefix: string, caseName: string) {
    return this.bkHttp.openCaseDemo<T>(bkPrefix, caseName);
  }

  // user
  public getUser<T>(args: IGetUserArgs) {
    return this.bkHttp.getUser<T>(args);
  }

  public async setSteamTradeLink<T>(steamTradeUrl: string, token: string, args: Partial<IGetUserArgs>) {
    return await this.bkHttp.setSteamTradeLink<T>(steamTradeUrl, token, args);
  }

  public async setSteamLogin<T>(steamLogin: string, token: string, args: Partial<IGetUserArgs>) {
    return await this.bkHttp.setSteamLogin<T>(steamLogin, token, args);
  }

  // inventory
  public getNewInventory<T>(arg: ICreateAuthUrlArg, page: number) {
    return this.bkHttp.getNewInventory<T>(arg, page);
  }

  public getOldInventory<T>(arg: ICreateAuthUrlArg, page: number) {
    return this.bkHttp.getOldInventory<T>(arg, page);
  }

  // item

  public sellItemInventory<T>(args: ISellItemArgs) {
    return this.bkHttp.sellItemInventory<T>(args);
  }

  public sellAllItemInventory<T>(args: ICreateAuthUrlArg) {
    return this.bkHttp.sellAllItemInventory<T>(args);
  }

  public sendItem<T>(args: ISendItemArgs) {
    return this.bkHttp.sendItem<T>(args);
  }

  // Замена предмета
  public replaceItem<T>(args: IReplaceItemArgs) {
    return this.bkHttp.replaceItem<T>(args);
  }
}
