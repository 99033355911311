import ApiHttp from '~/api/global/http/ApiHttp';
import type {
  IGetCaseByNameArgs,
  IGetUserArgs,
  IOpenCaseArgs,
  ISellItemArgs,
  ICreateAuthUrlArg,
  IReplaceItemArgs,
  ISendItemArgs,
} from '~/repository/modules/bk/bk.types';

export default class BkHttp extends ApiHttp {
  // cases
  public getAll<T>(bkPrefix: string) {
    return useAsyncData(() => this.$api.get<{ data: T }>(bkPrefix + 'cases'));
  }

  public getCaseByName<T>({ caseName, createAuthUrl }: IGetCaseByNameArgs) {
    const { url, headers } = createAuthUrl(`cases/${caseName}`);
    return useAsyncData(() => this.$bkApi.get<{ data: T }>(url, { headers }));
  }

  public getCaseByNameForDemo<T>(bkPrefix: string, caseName: string, language: string) {
    return useAsyncData(() => this.$bkApi.get<{ data: T }>(bkPrefix + `cases/${caseName}?language_iso=${language}`));
  }

  public async openCase<T>({ caseName, createAuthUrl }: IOpenCaseArgs) {
    const { url, headers } = createAuthUrl(`cases/${caseName}/open`);
    return await this.$bkApi.post<{ data: T }>(url, {
      body: {
        case: caseName,
      },
      headers,
    });
  }

  public async openCaseDemo<T>(bkPrefix: string, caseName: string) {
    return await this.$bkApi.post<{ data: T }>(bkPrefix + `cases/${caseName}/open`, {
      body: {
        case: caseName,
        testmode: true,
      },
    });
  }

  // user

  public async getUser<T>({ bkPrefix, currency, language, authToken }: IGetUserArgs) {
    const { data } = await this.$bkApi.get<{ data: T }>(
      bkPrefix + `user?language_iso=${language}&currency_iso=${currency}`,
      {
        headers: {
          'Bk-Auth-Token': authToken,
        },
      },
    );
    return data;
  }

  public setSteamTradeLink<T>(steamTradeUrl: string, token: string, { bkPrefix }: Partial<IGetUserArgs>) {
    return this.$bkApi.post<T>(bkPrefix + `user/steam/set/trade/link`, {
      body: {
        link: steamTradeUrl,
      },
      headers: {
        'Cookie': 'external_integrator=1',
        'Bk-Auth-Token': token,
      },
    });
  }

  public setSteamLogin<T>(steamLogin: string, token: string, { bkPrefix }: Partial<IGetUserArgs>) {
    return this.$bkApi.post<T>(bkPrefix + `user/steam/set/login`, {
      body: {
        steamLogin,
      },
      headers: {
        'Cookie': 'external_integrator=1',
        'Bk-Auth-Token': token,
      },
      mapBeforeSend: false,
    });
  }

  // inventory
  public async getNewInventory<T>({ createAuthUrl }: ICreateAuthUrlArg, page: number) {
    const { url, headers } = createAuthUrl(`inventory/new/items`);
    const { data } = await this.$bkApi.post<{ data: T }>(url, {
      body: {
        page,
      },
      headers,
    });
    return data;
  }

  public async getOldInventory<T>({ createAuthUrl }: ICreateAuthUrlArg, page: number) {
    const { url, headers } = createAuthUrl(`inventory/old/items`);
    const { data } = await this.$bkApi.post<{ data: T }>(url, {
      body: {
        page,
      },
      headers,
    });
    return data;
  }

  // item
  public sellItemInventory<T>({ id, createAuthUrl }: ISellItemArgs) {
    const { url, headers } = createAuthUrl(`inventory/sell/${id}`);
    return this.$bkApi.put<T>(url, { headers });
  }

  public sellAllItemInventory<T>({ createAuthUrl }: ICreateAuthUrlArg) {
    const { url, headers } = createAuthUrl(`inventory/sell/all`);
    return this.$bkApi.post<T>(url, { headers });
  }

  public sendItem<T>({ id, createAuthUrl, method }: ISendItemArgs) {
    const { url, headers } = createAuthUrl(`send/item`);
    return this.$bkApi.post<T>(url, {
      body: {
        id,
        method,
      },
      headers,
    });
  }

  // Замена предмета
  public replaceItem<T>({ createAuthUrl, replaceId, itemId }: IReplaceItemArgs) {
    const { url, headers } = createAuthUrl(`replace/item`);
    return this.$bkApi.post<T>(url, {
      body: {
        itemId,
        replaceId,
      },
      headers,
    });
  }
}
